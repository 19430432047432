<template>
  <div
    class="el-input el-input-group el-input-group--prepend m-0 creditorsInfoCurr"
  >
    <div class="el-input__wrapper">
      <input
        ref="inputRef"
        :readonly="readOnly"
        class="el-input__inner"
        type="text"
        autocomplete="off"
        tabindex="0"
        inputmode="numeric"
        id="el-id-6772-48"
      />
    </div>
  </div>
</template>

<script>
import { watch } from "vue";
import { parse, useCurrencyInput } from "vue-currency-input";
import { useField } from "vee-validate";

export default {
  name: "CurrencyInputNoSymbol",
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    modelValue: Number,
    options: Object,
    readOnly: Boolean,
  },
  setup(props) {
    const { inputRef, formattedValue, setValue } = useCurrencyInput(
      props.options
    );

    const { value, errorMessage } = useField(props.name);

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value);
      }
    );

    return { inputRef, formattedValue, errorMessage, value };
  },
};
</script>
